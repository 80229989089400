
import { Options, Vue } from "vue-class-component";
import MIcon from "@/components/MIcon.vue";
import MNavItem from "@/components/MNavItem.vue";
import { Getter, State } from "@/store/helper";
import store from "@/store";
import { SchoolConfig } from "@/entities/school";

export type PageType =
  | "home"
  | "student"
  | "notification"
  | "submission"
  | "room"
  | "staff"
  | "classroom"
  | "school"
  | "mini_app"
  | "other";

@Options({
  components: {
    MIcon,
    MNavItem
  }
})
export default class MNavBar extends Vue {
  showMenu = false;

  get current(): PageType {
    const studentPageNames = [
      "student_list",
      "student_profile",
      "student_history",
      "student_learning",
      "student_badge",
      "student_submissions",
      "group_list",
      "message_list"
    ];
    const notificationPageNames = ["notification"];
    const submissionPageNames = [
      "submission_master_list",
      "submission_master_detail"
    ];
    const staffPageNames = ["staff_list"];
    const classroomPageNames = ["classroom_list"];
    const roomPageNames = [
      "room_setting",
      "room_reservation_list",
      "room_student_list"
    ];
    const schoolPageNames = ["school_setting"];
    const miniappPageNames = ["mini_app_setting"];
    const homePageNames = ["userhome"];

    const currentName = this.$route.name;
    if (typeof currentName !== "string") {
      return "other";
    } else if (homePageNames.includes(currentName)) {
      return "home";
    } else if (studentPageNames.includes(currentName)) {
      return "student";
    } else if (notificationPageNames.includes(currentName)) {
      return "notification";
    } else if (submissionPageNames.includes(currentName)) {
      return "submission";
    } else if (staffPageNames.includes(currentName)) {
      return "staff";
    } else if (classroomPageNames.includes(currentName)) {
      return "classroom";
    } else if (roomPageNames.includes(currentName)) {
      return "room";
    } else if (schoolPageNames.includes(currentName)) {
      return "school";
    } else if (miniappPageNames.includes(currentName)) {
      return "mini_app";
    } else {
      return "other";
    }
  }

  get schoolAIManagementConsoleUrl() {
    const hostName = window.location.hostname;
    if (hostName.includes("mingaku.net")) {
      return "https://school-ai-app.mingaku.net/management";
    } else if (hostName.includes("mingaku-stg-hhgg9se.com")) {
      return "https://school-ai.mingaku-stg-hhgg9se.com/management";
    } else {
      return "https://school-ai.mingaku-dev-j82s7q6.com/management";
    }
  }

  @State("schoolConfig") schoolConfig?: SchoolConfig;
  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("isAdminOfSchool") isAdminOfSchool!: boolean;
  @Getter("isSupervisorOfSchool") isSupervisorOfSchool!: boolean;
  @Getter("isCramSchool") isCramSchool!: boolean;
  @Getter("isServiceProvider") isServiceProvider!: boolean;
  @Getter("isExclusiveSchoolAiUser") isExclusiveSchoolAiUser!: boolean;

  get isRoleLoaded(): boolean {
    return !!store.state.role;
  }

  get dynamicCss() {
    if (this.showMenu) {
      return {
        "menu-show": true
      };
    } else {
      return {};
    }
  }
}
